import { useSession } from '@zavy360/graphql/session';
import { Link, type LinkProps, useNavigate as useNavigation } from 'react-router-dom';

export function useNavigate(): ReturnType<typeof useNavigation> {
  return useNavigation();
}

export default function PracticeLink(props: LinkProps) {
  const { to, ...rest } = props;
  const { session } = useSession();
  return <Link {...rest} {...{ to }} />;
}
